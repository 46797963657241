import React from "react";
import Box from "./Box";
import Flex from "./Flex";
import Duimpje from "./images/Duimpje";
import StrikeThru from "./StrikeThru";
import { H2, H3, P, Span } from "./Typography";

interface Props {
  darkMode?: boolean;
}

const ActiePakket: React.FC<Props> = ({ darkMode }) => {
  const black = darkMode ? "white" : "black";
  const blue = darkMode ? "white" : "blue";

  return (
    <Box text-center>
      <H2 color={black} cursive upcase>
        Bekijk nu <br className="d-md-none" />
        <Span color={black} bold>
          Ons Actiepakket:
        </Span>
      </H2>

      <Flex
        justify-content="between"
        justify-content-md="start"
        align-items="center"
        px="3"
        mt="3"
        mt-md="4"
      >
        <Duimpje className="mr-5" />
        <P color={blue} className="mb-0" fontSize="large" bold>
          20 rijlessen <br className="d-md-none" />
          (60 minuten)
        </P>
      </Flex>

      <Flex
        justify-content="between"
        justify-content-md="start"
        align-items="center"
        px="3"
        mt="3"
      >
        <Duimpje className="mr-5" />
        <P color={blue} className="mb-0" fontSize="large" bold>
          Praktijkexamen
        </P>
      </Flex>

      <Flex
        justify-content="between"
        justify-content-md="start"
        align-items="center"
        px="3"
        mt="3"
      >
        <Duimpje className="mr-5" />
        <P color={blue} className="mb-0" fontSize="large" bold>
          Theorie leermiddelen
        </P>
      </Flex>

      <Flex
        justify-content="between"
        justify-content-md="start"
        align-items="center"
        px="3"
        mt="3"
      >
        <Duimpje className="mr-5" />
        <P color={blue} className="mb-0" fontSize="large" bold>
          Gratis herexamen
        </P>
      </Flex>

      <Flex justify-content="between" align-items="start" px="3" pt="4">
        <StrikeThru>
          <P color={black} fontSize="large" className="mb-0" bold>
            €1819,90
          </P>
        </StrikeThru>

        <H3 color={black} style={{ transform: "rotate(-10deg)" }} display>
          €1499
        </H3>
      </Flex>

      <Box mt="3">
        <P color={blue} className="mb-0" style={{ fontSize: "0.8em" }}>
          * Bij een minimum afname van 8 lessen is het herexamen gratis
        </P>
      </Box>
    </Box>
  );
};

export default ActiePakket;
