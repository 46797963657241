import React from "react";
import { Col, Container, Row } from "reactstrap";
import Box from "../components/general/Box";
import { H1, H2, P, Span } from "../components/general/Typography";
import BannerBackground from "../components/home/BannerBackground";
import Layout from "../components/layout/Layout";
import Button from "../components/general/Button";
import InstructeursLijst from "../components/instructeurs/InstructeursLijst";
import Head from "../components/general/Head";

interface Props {}

const instructeurs: React.FC<Props> = () => {
  return (
    <Layout>
      <Head page="instructeurs" />

      <BannerBackground height="auto">
        <Container>
          <Row>
            <Col
              xs="12"
              md={{ size: 8, offset: 2 }}
              lg={{ size: 6, offset: 3 }}
            >
              <Box my="5" text-center>
                <H1 upcase background="white" color="blue">
                  De instructeurs van Rijschool Lamar
                </H1>
              </Box>

              <Box text-center>
                <P fontSize="medium" color="white" bold>
                  Bij Rijschool Lamar in Leiden zitten twee vaste instructeurs
                  die de fijne kneepjes van het vak kennen. 1 man en 1 vrouw,
                  allebei gemotiveerd om jou zo snel mogelijk te laten slagen!
                </P>
              </Box>

              <Box text-center my="5">
                <Button to="/contact?message=Hoi Amar, <br><br>Ik wil graag een gratis proefles aanvragen!">
                  Gratis proefles!
                </Button>
              </Box>
            </Col>
          </Row>
        </Container>
      </BannerBackground>
      <Container>
        <Row>
          <Col xs="12" md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }}>
            <Box my="5" text-center>
              <H2 fontSize="xxl" color="blue">
                Onze instructeurs
              </H2>
              <P light className="my-4">
                Amar staat je met raad en daad bij op de (snel)weg. Ook is het
                mogelijk om van <Span bold>een deskundige vrouw</Span> les te
                krijgen. Je kunt hier uiteraard je voorkeur voor uitspreken.
              </P>
            </Box>
          </Col>

          <Col xs="12">
            <Box mb="5" mt-md="3">
              <InstructeursLijst />
            </Box>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default instructeurs;
