export type Pakket = {
  title: string;
  perks: string[];
  price: string;
  note?: string;
};

export const pakketten: Pakket[] = [
  {
    title: "A1",
    perks: [
      "10 Rijlessen €62,99 per uur (60 minuten)",
      "Proefexamen",
      "Praktijk examen",
    ],
    price: "949,90",
    note:
      "Uitsluitend voor mensen die wisselen van rijschool of herexamen doen. Het herexamen is gratis mits 8 lessen worden afgenomen.",
  },
  {
    title: "A2",
    perks: [
      "20 Rijlessen €60,99 per uur (60 minuten)",
      "Tussentijdse toets",
      "Praktijk examen",
      "BESPAAR €40",
    ],
    price: "1819,80",
  },
  {
    title: "A3",
    perks: [
      "30 Rijlessen €58,99 per uur (60 minuten)",
      "Tussentijdse toets",
      "Praktijk examen",
      "BESPAAR €60",
    ],
    price: "2369,70",
  },
  {
    title: "A4",
    perks: [
      "40 Rijlessen €56,99 per uur (60 minuten)",
      "Tussentijdse toets",
      "Praktijk examen",
      "BESPAAR €80",
    ],
    price: "2879,60",
  },
  {
    title: "Faalangst pakket",
    perks: [
      "50 Rijlessen €54,99 per uur (60 minuten)",
      "Tussentijdse toets",
      "Praktijkexamen faalangst",
      "Individueel theorie-examen",
      "Theorie boeken en leermateriaal",
      "Speciale begeleiding door een gecertificeerde faalangst instructeur",
    ],
    price: "4030",
  },
];

export type OverigPakket = {
  title: string;
  price: string;
};

export const overigePakketten: OverigPakket[] = [
  {
    title: "Losse les schakelauto (zonder pakket)",
    price: "62,99",
  },
  {
    title: "Losse les automaat (zonder pakket)",
    price: "62,99",
  },
  {
    title: "Praktijkexamen",
    price: "320",
  },
  {
    title: "Tussentijdse toets",
    price: "280",
  },
  {
    title: "Faalangst examen",
    price: "420",
  },
  {
    title: "Rijvaardigheidsonderzoek examen B-NO",
    price: "400",
  },
];
