import { OverigPakket, Pakket } from "./pakketInfo";

export const pakketten: Pakket[] = [
  {
    title: "1",
    perks: ["10 Rijlessen €58,- per les", "Praktijk examen AVB & AVD"],
    price: "1100",
  },
  {
    title: "2",
    perks: ["15 Rijlessen €56,- per les", "Praktijk examen AVB & AVD"],
    price: "1360",
  },
  {
    title: "3",
    perks: ["20 Rijlessen €54,- per les", "Praktijk examen AVB & AVD"],
    price: "1600",
  },
  {
    title: "4",
    perks: ["25 Rijlessen €53,- per les", "Praktijk examen AVB & AVD"],
    price: "1845",
  },
  {
    title: "5",
    perks: ["35 Rijlessen €52,- per les", "Praktijk examen AVB & AVD"],
    price: "2340",
  },
];

export const overigePakketten: OverigPakket[] = [
  {
    title: "AVB examen",
    price: "200",
  },
  {
    title: "AVD examen",
    price: "320",
  },
  {
    title: "Proefles 90 minuten",
    price: "55",
  },
  {
    title: "Losse les",
    price: "58",
  },
];
